<template>
  <div class="anomaly-subscription pa-10">

    <title-page icon="mdi-alert-circle-outline">
      {{ $t('anomaly.subscription-anomalies') }}
    </title-page>

    <v-expansion-panels inset>
      <template v-if="anomalies.organizationsWithoutSubscription.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Organisations sans abonnement
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.organizationsWithoutSubscription.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                ]"
                :items="anomalies.organizationsWithoutSubscription"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.chargingPointsWithoutLicence.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Bornes sans licence
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.chargingPointsWithoutLicence.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.chargingPointsWithoutLicence"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'charging-points.detail.licences', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization._id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.meterDevicesWithoutLicence.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Compteur sans licence
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.meterDevicesWithoutLicence.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                          :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                          :items="anomalies.meterDevicesWithoutLicence"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'meter-device.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization._id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeChargingPointsWithoutEnergyPrice.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Bornes sans Tarification au kWh
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeChargingPointsWithoutEnergyPrice.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.homeChargingPointsWithoutEnergyPrice"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'charging-points.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeMeterDevicesWithoutEnergyPrice.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Compteur sans Tarification au kWh
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeMeterDevicesWithoutEnergyPrice.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.homeMeterDevicesWithoutEnergyPrice"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'meter-device.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeChargingPointsEnergyPriceTooBig.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Bornes avec Tarification au kWh excessive ( > 0,5 €)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeChargingPointsEnergyPriceTooBig.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('charging-point.energy-provider-kwh-price'), value: 'energyProviderKwhPrice', align: 'left'},
                ]"
                :items="anomalies.homeChargingPointsEnergyPriceTooBig"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'charging-points.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.energyProviderKwhPrice="{value}">
               {{ value }} €
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeMeterDevicesEnergyPriceTooBig.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Compteur avec Tarification au kWh excessive ( > 0,5 €)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeMeterDevicesEnergyPriceTooBig.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                          :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('charging-point.energy-provider-kwh-price'), value: 'energyProviderKwhPrice', align: 'left'},
                ]"
                          :items="anomalies.homeMeterDevicesEnergyPriceTooBig"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'charging-points.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.energyProviderKwhPrice="{value}">
                {{ value }} €
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Organisations (avec borne à domicile) sans abonnement de remboursement
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                ]"
                :items="anomalies.organizationWithHomeChargingPointsWithoutExpenseSubscription"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Bornes à domicile sans licence de remboursement
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.homeChargingPointsWithoutExpenseSubscriptionLicence"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'charging-points.detail.licences', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Compteur à domicile sans licence de remboursement
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                          :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                          :items="anomalies.homeMeterDevicesWithoutExpenseSubscriptionLicence"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'meter-device.detail', params: {id: item._id}}" target="_blank">
                  {{ item.name }}
                </router-link>
              </template>
              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseSubscriptionWithoutTariffType.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Abonnements de remboursement sans type de Tarification
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseSubscriptionWithoutTariffType.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.expenseSubscriptionWithoutTariffType"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseSubscriptionOrganizationWithoutPrice.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Abonnements de remboursement sans Tarification au kWh
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseSubscriptionOrganizationWithoutPrice.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.expenseSubscriptionOrganizationWithoutPrice"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Abonnements de remboursement avec Tarification Excessive au kWh ( > 0,5 €)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.expenseSubscriptionOrganizationEnergyPriceTooBig"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions.edit', params: {id: item.organization._id, subscriptionId: item._id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.memberWithNoCorporateToken.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres sans badge pro
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.memberWithNoCorporateToken.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.memberWithNoCorporateToken"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'member.tokens', params: {id: item.organization.id, memberId: item._id}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseMemberWithoutIban.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres sans IBAN
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseMemberWithoutIban.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                ]"
                :items="anomalies.expenseMemberWithoutIban"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'member.edit', params: {id: item.organization.id, memberId: item._id}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseMemberWithHighAmount.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres ayant un montant de remboursement excessif ( > 400 €)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseMemberWithHighAmount.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('amount'), value: 'amount', align: 'left'},
                ]"
                :items="anomalies.expenseMemberWithHighAmount"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>


              <template #item.amount="{item}">
                {{ item.amount.toFixed(2) }} €
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseMemberWithNoAmount.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres ayant des charges et un montant de remboursement à 0 €
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseMemberWithNoAmount.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('amount'), value: 'amount', align: 'left'},
                ]"
                :items="anomalies.expenseMemberWithNoAmount"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>


              <template #item.amount="{item}">
                {{ item.amount.toFixed(2) }} €
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseMemberTooManyCdr.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres ayant trop de ligne de remboursement ( > 100 lignes)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseMemberTooManyCdr.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('nb-lines'), value: 'count', align: 'left'},
                ]"
                :items="anomalies.expenseMemberTooManyCdr"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.subscriptions', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseLineWithHighEnergy.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membre avec consommation élevée de kWh à domicile (charge > 100 kWh)
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseLineWithHighEnergy.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},
                    {text: $t('kWh'), value: 'energy'},

                ]"
                :items="anomalies.expenseLineWithHighEnergy"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.expense-periods.member', params: {id: item.organization.id, memberId: item._id, periodId: item.expensePeriodId}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.detail', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>

              <template #item.energy="{item}">
                  {{ (item.energy/1000).toFixed(3) }} kWh
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.expenseMemberWithoutExpenseLine.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Membres sans ligne de remboursement
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.expenseMemberWithoutExpenseLine.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('name'), value: 'name'},
                    {text: $t('organization.organization'), value: 'organization.name', align: 'left'},

                ]"
                :items="anomalies.expenseMemberWithoutExpenseLine"
            >
              <template #item.name="{item}">
                <router-link :to="{name: 'organizations.expense-periods.manage', params: {id: item.organization.id, periodId: item.expensePeriodId}}" target="_blank">
                  {{ customerLabel(item) }}
                </router-link>
              </template>

              <template #item.organization.name="{item}">
                <router-link :to="{name: 'organizations.detail', params: {id: item.organization.id}}" target="_blank">
                  {{ item.organization.name }}
                </router-link>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

      <template v-if="anomalies.corporateTokenWithWrongLink.length > 0">
        <v-expansion-panel cols="12" md="6">
          <v-expansion-panel-header>
            <v-row>
              <v-col class="text">
                Badges entreprise avec lien invalide
              </v-col>
              <v-col class="text-right pr-6 font-weight-light">
                {{anomalies.corporateTokenWithWrongLink.length}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table dense
                :headers="[
                    {text: $t('Badge Uid'), value: 'uid'},
                    {text: $t('name'), value: 'name'},
                    {text: $tc('customer.customer', 1), value: 'customer.lastname', align: 'left'},

                ]"
                :items="anomalies.corporateTokenWithWrongLink"
            >
              <template #item.uid="{item}">
                <a href="#"  @click.prevent="openRfidModal(item)">{{ item.uid }}</a>
              </template>

              <template #item.customer.lastname="{item}">
                <template v-if="item.customer">
                  <router-link :to="{name: 'customer.detail', params: {id: item.customer.id}}" target="_blank">
                    <template v-if="item.customer?.lastname">
                      {{ item.customer.firstname }} {{ item.customer.lastname }}
                    </template>
                    <template v-else>
                      {{ item.customer?.evcoId }}
                    </template>
                  </router-link>
                </template>
                <template v-else>
                  Pas de lien avec un client
                </template>

              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>

    </v-expansion-panels>

    <rfid-form-modal ref="rfidFormModal" @change="loadData" />


  </div>
</template>

<script>
import AnomalyRepository from "@repository/AnomalyRepository";
import RfidFormModal from "@modals/RfidFormModal.vue";

export default {
  components: {RfidFormModal},

  data() {
    return {
      anomalies: {
        organizationsWithoutSubscription: [],

        chargingPointsWithoutLicence: [],
        homeChargingPointsWithoutEnergyPrice: [],
        homeChargingPointsEnergyPriceTooBig: [],
        homeChargingPointsWithoutExpenseSubscriptionLicence: [],

        meterDevicesWithoutLicence: [],
        homeMeterDevicesWithoutEnergyPrice: [],
        homeMeterDevicesEnergyPriceTooBig: [],
        homeMeterDevicesWithoutExpenseSubscriptionLicence: [],

        organizationWithHomeChargingPointsWithoutExpenseSubscription: [],
        expenseSubscriptionWithoutTariffType: [],
        expenseSubscriptionOrganizationWithoutPrice: [],
        expenseSubscriptionOrganizationEnergyPriceTooBig: [],
        memberWithNoCorporateToken: [],
        expenseMemberWithNoAmount: [],
        expenseMemberWithoutIban: [],
        expenseMemberWithHighAmount: [],
        expenseMemberTooManyCdr: [],
        expenseLineWithHighEnergy: [],
        expenseMemberWithoutExpenseLine: [],
        corporateTokenWithWrongLink: [],



      },
    }
  },


  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.showLoading(true)
      AnomalyRepository.subscription().then(response => {
        this.anomalies = response
        this.showLoading(false)
      }).catch(e => {
        this.notifyError(e)
        this.showLoading(false)
      })
    },

    customerLabel(item) {
      let tmp = []
      if(item.firstname) {
        tmp.push(item.firstname)
      }
      if(item.lastname) {
        tmp.push(item.lastname)
      }
      if(tmp.length === 0) {
        if(item.customer?.evcoId) {
          tmp.push(item.customer.evcoId)
        } else {
          tmp.push('no-name')
        }
      }
      return tmp.join(' ')
    },


    openRfidModal(item) {
      console.log(item)
      this.$refs.rfidFormModal.open({rfidId: item?._id})
    }
  }
}
</script>

<style lang="scss">
.anomaly-subscription {
  .v-data-table td.text-start, .v-data-table th.text-start {
    text-align: left !important;
  }
}
</style>
